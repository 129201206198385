@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.backgroung-view {
/* stars_001 */

position: fixed;
width: 100%;
height: 755.54px;
left: 0px;
top: 0px;
/* background-repeat: repeat; */

background: linear-gradient(180deg, rgba(5, 5, 22, 0) 58.33%, #050516 100%), url('/var/www/project_app/src/stars_001.png');
/* background-image: url('/var/www/project_app/src/vs_icon.svg'); */
mix-blend-mode: lighten;
}

.card-hot-event {
/* hot event card */
position: relative;
width: 400px;
height: 303px;

filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.851207));
border-radius: 11px;

}

.forground {
/* hot event card */


position: relative;
width: 400px;
height: 303px;

filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.851207));
border-radius: 11px;

background: url(card_btts_bg_image.png);
}

.forground_gold {
  /* hot event card */
  
  
  position: relative;
  width: 400px;
  height: 303px;
  
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.851207));
  border-radius: 11px;
  border: 1px solid #A68B58;
  
  background: url(card_btts_bg_image.png);
  background-size: cover;
  }

  .forground_btts {
    /* hot event card */
    
    
    position: relative;
    width: 400px;
    height: 300px;
    
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.851207));
    border-radius: 11px;
    border: 1px solid #A68B58;
    
    background: url(card_btts_bg_image.png);
    background-size: cover;
    }
  
.liga-information-block {

  /* liga information block */


/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 10px;

position: absolute;
height: 39px;
left: 10px;
right: 10px;
top: 9px;
}

.liga-info {
/* liga info */
/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 10px;

width: 300px;
height: 35px;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 1;
}

.liga-icon {
/* liga icon */
width: 32px;
height: 32px;

/* background: url(image.png); */
background: #FFFFFF;
border-radius: 80px;
filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.507348));
border-radius: 40px;

/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}


.liga-name-and-country-info {
/* liga name and country info */
/* Auto layout */
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 5px;

width: 258px;
height: 35px;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 1;
}

.league-name {
/* Bundesligue */
width: 258px;
height: 15px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 15px;
/* identical to box height, or 125% */
display: flex;
align-items: center;

color: #FFFFFF;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.country-info {
/* country info */
/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 5px;

width: 357px;
height: 15px;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
}

.country-flag {
/* country flag */
width: 15px;
height: 15px;

/* background: url(image.png); */
border-radius: 15px;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;  
}

.country-name {
/* Germany */
width: 337px;
height: 15px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 8px;
line-height: 15px;
/* identical to box height, or 188% */
display: flex;
align-items: center;
text-transform: uppercase;

color: #80858C;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
}

.date-time {
/* 28 Oct 16:00 */
width: 90px;
height: 15px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 15px;
/* identical to box height, or 125% */
display: flex;
justify-content: right;
text-align: right;

color: #FFFFFF;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;  
}

.live {
/* live */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px 10px;

width: 70px;
height: 15px;

background: linear-gradient(97.29deg, #B0F320 0%, #679406 101.94%);
border-radius: 5px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;  
}

.status-live {

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px 10px;

width: 70px;
height: 15px;

background: linear-gradient(97.29deg, #DC5252 0%, #CF1D1D 101.94%);
border-radius: 5px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.status-finished {
/* live */
/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px 10px;

width: 55px;
height: 15px;

background: linear-gradient(97.29deg, #1C1C1C 0%, #0F0F0F 101.94%);
border-radius: 5px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.live-text {
 /* live */
width: 70px;
height: 15px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 7px;
line-height: 15px;
/* identical to box height, or 214% */
display: flex;
justify-content: center;
text-align: center;
letter-spacing: 0.5px;
text-transform: uppercase;
/* dark_color */
color: #2E2E2E;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}
.live-text-online {
  /* live */
 width: 70px;
 height: 15px;
 
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 700;
 font-size: 7px;
 line-height: 15px;
 /* identical to box height, or 214% */
 display: flex;
 justify-content: center;
 text-align: center;
 letter-spacing: 0.5px;
 text-transform: uppercase;
 /* dark_color */
 color: #fff;

 /* Inside auto layout */
 flex: none;
 order: 0;
 flex-grow: 0;
 }
.finished-text {
  /* live */
 width: 70px;
 height: 15px;
 
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 700;
 font-size: 7px;
 line-height: 15px;
 /* identical to box height, or 214% */
 display: flex;
 justify-content: center;
 text-align: center;
 letter-spacing: 0.5px;
 text-transform: uppercase;
 /* dark_color */
 color: #5c5c5c;
 /* Inside auto layout */
 flex: none;
 order: 0;
 flex-grow: 0;
 }

.date-live{
/* date live */
/* Auto layout */
display: flex;
flex-direction: column;
align-items: flex-end;
padding: 0px;
gap: 5px;

width: 70px;
height: 39px;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
}

.teams-block {
/* teams block */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: right;
align-items: center;
padding: 0px 10px;
gap: 10px;

position: absolute;
width: 400px;
height: 124px;
left: 0px;
top: 87px;
}


.team1 {
/* team 1 */
/* Auto layout */
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
gap: 6px;

width: 150px;
height: 124px;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;  
}

.team2 {
/* team 2 */


/* Auto layout */

display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
gap: 6px;

width: 150px;
height: 124px;


/* Inside auto layout */

flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;  
}

.team-img-1 {
/* teamImg */
/* Auto layout */
flex: none;
/* display: flex; */
flex-direction: column;
justify-content: center;
/* align-items: center; */
padding: 5px;

width: 80px;
height: 80px;

background: #FFFFFF;
border-radius: 80px;

/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0; 
}

.team-img-2 {
/* teamImg */
/* Auto layout */
flex: none;
/* display: flex; */
flex-direction: column;
justify-content: center;
align-items: center;
padding: 5px;

width: 80px;
height: 80px;

background: #FFFFFF;
border-radius: 80px;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}

.team-logo-1 {
/* team 1 logo */
width: 80px;
height: 80px;

/* background: url(image.png); */
/* Inside auto layout */
flex: none;
order: 0;
/* justify-content: center; */
flex-grow: 1;  
}

.team-name-1 {
/* F.C. Juventus */
width: 150px;
height: 230px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 15px;
/* or 100% */

text-align: center;

color: #FFFFFF;

text-shadow: 0px 1px 0px #000000;

/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;  
}

.team-logo-2 {
/* team 2 logo */
width: 80px;
height: 80px;
/* background: url(image.png); */
/* Inside auto layout */
flex: none;
order: 0;
/* justify-content: center; */
flex-grow: 1; 
}
  
.team-name-2 {
  /* F.C. Juventus */
width: 150px;
height: 230px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 15px;
/* or 100% */
text-align: center;

color: #FFFFFF;

text-shadow: 0px 1px 0px #000000;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0; 
}

.score-vertical {
/* score/vertical */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 10px;
gap: 5px;

width: 81px;
height: 30px;

background: linear-gradient(180deg, #3F3F3F 0%, #000000 97.89%);
box-shadow: 2px 10px 20px rgba(33, 35, 37, 0.35), inset 0.5px 1px 1px rgba(255, 255, 255, 0.8), inset -0.5px -1px 0px rgba(255, 255, 255, 0.5);
border-radius: 5px;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
} 

.score-vertical-vs {
  width: 101px;
  height: 124px;
  background-image: url('/var/www/project_app/src/vs_icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border: none; /* Remove the border */
}


.score-team-1, .score-team-2 {
  color: #fff;
  margin: 0px 10px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 -1px 0 #0e0e0e;
  width: 100%;
}

.divider-3 {
height: 100% ; 
width: 4px; 
background-color: rgba(255,255,255,0.2);
}

.event-info {
/* eventInfo */
box-sizing: border-box;
/* Auto layout */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px 15px;

position: absolute;
width: 136px;
height: 20px;
left: 132px;
top: 240px;
/* lime green */
background: #B0F320;
border: 0.5px solid #AEAAAA;
backdrop-filter: blur(2.5px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 5px 5px 0px 0px;  
}

.event-info-gold {
  /* eventInfo */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  
  position: absolute;
  width: 136px;
  height: 20px;
  left: 132px;
  top: 240px;
  /* lime green */
  background: #EEE858;
  border: 0.5px solid #AEAAAA;
  backdrop-filter: blur(2.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px 5px 0px 0px;  
}

.ml-view {
/* Machine learning vie */
width: 109px;
height: 15px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 8px;
line-height: 15px;
/* identical to box height, or 188% */
display: flex;
justify-content: center;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;
/* dark_color */
color: #2E2E2E;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;  
}

.pred-block {
/* prediction block */
/* Auto layout */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;
gap: 10px;

position: absolute;
width: 400px;
height: 40px;
left: 0px;
top: 260px;

background: rgba(28, 28, 28, 0.9);
/* lime green */
border-top: 1px solid #B0F320;
border-radius: 0px 0px 10px 10px;  
}

.pred-block-gold {
  /* prediction block */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  
  position: absolute;
  width: 400px;
  height: 40px;
  left: 0px;
  top: 260px;
  
  background: rgba(28, 28, 28, 0.9);
  /* lime green */
  border-top: 1px solid #EEE858;
  border-radius: 0px 0px 10px 10px;  
  }

.pred-data {
/* prediction data */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;

width: 400px;
height: 40px;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;  
}

.pred-elem-1 {
/* prediction elem 1 */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 0px;

width: 132.67px;
height: 22px;
/* pred_green */
/* background: radial-gradient(50.25% 522.32% at 50.25% 50.79%, rgba(51, 69, 12, 0.7) 0%, rgba(32, 44, 6, 0) 100%); */
background: rgba(53, 52, 52, 0.2);
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
}

.pred-elem-x {
/* prediction elem 2 */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 0px;

width: 132.67px;
height: 22px;
/* pred_grey */
background: rgba(53, 52, 52, 0.2);
/* Inside auto layout */
flex: none;
order: 2;
align-self: stretch;
flex-grow: 1;
}

.pred-elem-2 {
/* prediction elem 3 */
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 0px;

width: 132.67px;
height: 22px;
/* pred_grey */
background: rgba(53, 52, 52, 0.2);
/* Inside auto layout */
flex: none;
order: 4;
align-self: stretch;
flex-grow: 1;
}

.pred-text {
/* 72% */
width: 132.67px;
height: 22px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 20px;
/* or 133% */

display: flex;
justify-content: center;
text-align: center;

/* pred_color_neutral */

color: #E4E4E4;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
}

.divider-1 {
/* divider */
width: 1px;
height: 40px;

background: #333333;
mix-blend-mode: normal;
border-radius: 5px;
/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;  
}

.divider-2 {
/* divider */
width: 1px;
height: 40px;

background: #333333;
mix-blend-mode: normal;
border-radius: 5px;
/* Inside auto layout */
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
}

.card-bg { 
/* card bg */
position: absolute;
width: 400px;
height: 300px;
left: 1px;
top: 0px;

border-radius: 10px;
} 

.card-bg-finished {
/* before gradient to bottom */
position: absolute;
width: 400px;
height: 300px;
left: 0px;
top: 0px;

background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 65.46%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
mix-blend-mode: normal;
opacity: 0.9;
}
